import React from "react"
import { AspectRatio, Box, Button, Flex, Text, Image, Grid, Spacer, Select } from "@chakra-ui/react"
import Link from "@app/components/Link"
import ProductPrice from "@app/components/Product/ProductPrice"
import { useCart } from "@app/hooks/useCart"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"
import { useShopify } from "@app/hooks/useShopify"

type Props = {
  item: any
}

const CartItem: React.FC<Props> = ({ item }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { loading: cartLoading, removeFromCart, updateQuantity } = useCart()
  const { formatMoney } = useShopify()
  const loading = cartLoading

  const selectedSize = item?.variant?.selectedOptions?.find(option => option.name === "Size")?.value
  const selectedColour = item?.variant?.selectedOptions?.find(option => option.name === "Colour")?.value

  return (
    <Box>
      <Grid templateColumns={["115px 1fr 80px", "1.5fr 3.5fr 1.5fr 1.5fr 1fr 1fr"]} mt={4}>
        <Box w={115} flexShrink={0}>
          <AspectRatio ratio={1 / 1}>{item?.variant?.image && <Image {...item.variant.image} />}</AspectRatio>
        </Box>
        <Flex direction={"column"} align="start">
          <Link to={urlResolver(item?.variant?.product, routes.PRODUCT)?.url}>
            <Text size="14" fontWeight="600" mb={4}>
              {item.title}
            </Text>
          </Link>
          <Text size="12" color={"grey.700"}>
            Colour: {selectedColour}
          </Text>
          <Text size="12" color={"grey.700"} d={["block", "none"]} mb={2}>
            Size: {selectedSize}
          </Text>
          <Spacer />
          <Select
            w={20}
            h={"30px"}
            fontSize={"14px"}
            value={item?.quantity}
            isDisabled={loading}
            onChange={({ target: { value } }) => updateQuantity(item?.variant?.id, parseInt(value))}
            d={["block", "none"]}
          >
            {Array.from(new Array(10).keys()).map(number => (
              <option key={number.toString()} value={number + 1}>
                {number + 1}
              </option>
            ))}
          </Select>
          <Button
            variant="link"
            disabled={loading}
            textTransform="unset"
            textDecoration="unset"
            fontWeight="400"
            letterSpacing="0"
            onClick={() => removeFromCart(item?.variant?.id)}
            size="sm"
            d={["none", "block"]}
          >
            Remove
          </Button>
        </Flex>
        <Box d={["none", "block"]}>
          <Text size="14">{selectedSize}</Text>
        </Box>
        <Box d={["none", "block"]}>
          <ProductPrice variant={item?.variant} small={true} />
        </Box>
        <Box d={["none", "block"]}>
          <Select
            w={20}
            h={"30px"}
            fontSize={"14px"}
            value={item?.quantity}
            isDisabled={loading}
            onChange={({ target: { value } }) => updateQuantity(item?.variant?.id, parseInt(value))}
          >
            {Array.from(new Array(10).keys()).map(number => (
              <option key={number.toString()} value={number + 1}>
                {number + 1}
              </option>
            ))}
          </Select>
        </Box>
        <Flex direction="column" justify="space-between" align="flex-end">
          <Box>
            <Text size={"14"} fontWeight={600} textAlign="right">
              {formatMoney(item.variant?.priceV2?.amount ? Number(item.variant?.priceV2?.amount) * item?.quantity : 0)}
            </Text>
          </Box>
          <Button
            variant="link"
            disabled={loading}
            textTransform="unset"
            textDecoration="unset"
            fontWeight="400"
            letterSpacing="0"
            onClick={() => removeFromCart(item?.variant?.id)}
            size="sm"
            d={["block", "none"]}
          >
            Remove
          </Button>
        </Flex>
      </Grid>
    </Box>
  )
}

export default React.memo(CartItem)
