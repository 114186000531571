import React from "react"
import { VStack, Text, Grid, Box, Divider, StackDivider } from "@chakra-ui/react"
import { useCheckoutContext } from "@app/providers/checkout"
import CartItem from "./CartItem"

const CartList: React.FC = () => {
  const { checkout, count } = useCheckoutContext()
  return count > 0 ? (
    <>
      <Grid templateColumns={["115px 1fr 80px", "1.5fr 3.5fr 1.5fr 1.5fr 1fr 1fr"]} mt={[0, 4]}>
        <Box>
          <Text textTransform={"uppercase"} color={"grey.700"} size="12">
            Product
          </Text>
        </Box>
        <Box>
          <Text textTransform={"uppercase"} color={"grey.700"} size="12">
            Description
          </Text>
        </Box>
        <Box d={["none", "block"]}>
          <Text textTransform={"uppercase"} color={"grey.700"} size="12">
            Size
          </Text>
        </Box>
        <Box d={["none", "block"]}>
          <Text textTransform={"uppercase"} color={"grey.700"} size="12">
            Item Price
          </Text>
        </Box>
        <Box d={["none", "block"]}>
          <Text textTransform={"uppercase"} color={"grey.700"} size="12">
            Quantity
          </Text>
        </Box>
        <Box d={["none", "block"]}>
          <Text textAlign={"right"} w={"full"} textTransform={"uppercase"} color={"grey.700"} size="12">
            Total
          </Text>
        </Box>
      </Grid>
      <Divider my={"10px !important"} />
      <VStack spacing={[4, 8]} alignItems={"stretch"} justifyContent="flex-start" divider={<StackDivider borderColor="grey.500" />}>
        {checkout?.lineItems?.map((item: any) => (
          <CartItem key={item?.id} item={item} />
        ))}
      </VStack>
    </>
  ) : (
    <Text textAlign="center">You don&#39;t have any items in your cart.</Text>
  )
}

export default React.memo(CartList)
