import React from "react"
import { Button } from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"
import { useCheckoutContext } from "@app/providers/checkout"

const Checkout: React.FC = () => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { checkout, loading, gotoCheckout } = useCheckoutContext()

  const disabled = !checkout?.lineItems?.length

  return !disabled ? (
    <Button onClick={gotoCheckout} isLoading={loading}>
      Checkout Now
    </Button>
  ) : (
    <Button as="a" href={routes.HOMEPAGE}>
      Start Shopping
    </Button>
  )
}

export default React.memo(Checkout)
