import React from "react"
import { Box, VStack, HStack, Text, Divider, Tag, TagLabel, TagCloseButton, Flex } from "@chakra-ui/react"
import { useCheckoutContext } from "@app/providers/checkout"
import { useCheckout } from "@app/hooks/useCheckout"
import { useShopify } from "@app/hooks/useShopify"
import { PaymentIcons } from "../PaymentIcons"

const CartSummary: React.FC = () => {
  const { checkout, count } = useCheckoutContext()
  const { removeDiscountCode } = useCheckout()
  const { formatMoney } = useShopify()

  const currencyCode = checkout?.currencyCode
  const amountDue = formatMoney(checkout?.paymentDueV2?.amount)
  const subTotal = formatMoney(checkout?.lineItemsSubtotalPrice?.amount)
  // const shippingText =
  //   checkout?.shippingLine?.price && checkout?.shippingLine?.price !== "0.0"
  //     ? formatMoney(checkout?.shippingLine?.price)
  //     : "Calculated at checkout"
  const appliedDiscountCode = checkout?.discountApplications?.[0]?.code
  const discountApplied = parseFloat(checkout?.lineItemsSubtotalPrice?.amount) - parseFloat(checkout?.subtotalPriceV2?.amount)
  const formattedDiscountApplied = formatMoney(
    parseFloat(checkout?.lineItemsSubtotalPrice?.amount) - parseFloat(checkout?.subtotalPriceV2?.amount)
  )

  return (
    <>
      <Divider />
      <Flex>
        <Box w={"50%"} pr={4} d={["none", "block"]}>
          <PaymentIcons />
        </Box>
        <Box w={["100%", "50%"]} textAlign="left">
          {count > 0 ? (
            <VStack width="full" align="stretch">
              <HStack justify="space-between">
                <Text size="14">Subtotal (Includes GST)</Text>
                <Text size="14" fontWeight="600">
                  {subTotal}
                </Text>
              </HStack>
              {discountApplied > 0 ? (
                <HStack display="flex" alignItems="center" justify="space-between">
                  <Text as={"div"} display="flex" alignItems="center">
                    <Text size="14" mr={4}>
                      Discounts
                    </Text>
                    <Tag variant="discount">
                      <TagLabel>{appliedDiscountCode}</TagLabel>
                      <TagCloseButton onClick={() => removeDiscountCode()} />
                    </Tag>
                  </Text>
                  <Text size="14">-&nbsp;{formattedDiscountApplied}</Text>
                </HStack>
              ) : null}
              <Divider />
              <HStack justify="space-between">
                <Text size="14" textTransform="uppercase">
                  Total
                </Text>
                <Text size="14" fontWeight="600">
                  {currencyCode} {amountDue}
                </Text>
              </HStack>
            </VStack>
          ) : null}
        </Box>
      </Flex>
    </>
  )
}

export default React.memo(CartSummary)
