import React from "react"
import { Box, Button, Container, Flex } from "@chakra-ui/react"
import { PageProps } from "@root/types/global"
import Checkout from "@app/components/Checkout"
import { useContent } from "@app/hooks/useContent"
import CartSummary from "./CartSummary"
import CartList from "./CartList"
import Title from "@app/components/Sections/Title/Title"
import { useConfigContext } from "@app/providers/config"

const Cart: React.FC<PageProps<any>> = ({ page }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const content = useContent(page)

  return (
    <>
      <Title title={page?.title} />
      <Container maxW={"container.lg"}>
        <Box as="section" px={4} pb={8} textAlign="center">
          {content}
        </Box>
        <Box as="section" py={8}>
          <CartList />
          <CartSummary />
        </Box>
        <Box as="section" py={8} pb={16}>
          <Flex justify="space-between" direction={["column-reverse", "row"]} gap={4}>
            <Button as="a" href={routes.HOMEPAGE} variant="ghost" size="sm" textTransform="unset" mt={[4, 0]}>
              Continue Shopping
            </Button>
            <Checkout />
          </Flex>
        </Box>

        {/* Hide cart recommendations */}
        {/* {checkout?.lineItems?.length > 0 && <CartRecommended title="You may also like" />} */}
      </Container>
    </>
  )
}

export default Cart
